import axios from "@/plugins/axios"

const invites = {
    state: {
        invites: [],
        invitesLoadingState: false,
    },
    getters: {
        invites: state => state.invites,
        invitesLoadingState: state => state.invitesLoadingState,
    },
    mutations: {
        setInvites: (state, invites) => state.invites = invites,
        setInvitesLoadingState: (state, isLoading) => state.invitesLoadingState = isLoading,
        addInvite: (state, invite) => state.invites.unshift(invite),
        updateInvite: (state, invite) => {
            const inviteIndex = state.invites.map(invite => invite.invite_id).indexOf(invite.invite_id)
            state.invites.splice(inviteIndex, 1, invite)
        },
        deleteInvite: (state, invite) => {
            const inviteIndex = state.invites.map(invite => invite.invite_id).indexOf(invite.invite_id)
            state.invites.splice(inviteIndex, 1)
        }
    },
    actions: {
        invitesGetRequest: ({commit}) => {
            commit('setInvitesLoadingState', true)
            return new Promise((resolve, reject) => {
                axios.get('/admin/invites')
                    .then(response => {
                        commit('setInvites', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('setInvites', [])
                        reject(error)
                    })
                    .finally(() => {
                        commit('setInvitesLoadingState', false)
                    })
            })
        },
        invitesUpdateRequest: ({commit}, invite) => {
            commit('setInvitesLoadingState', true)
            return new Promise((resolve, reject) => {
                axios.patch(`/admin/invites/${invite.invite_id}`, invite)
                    .then(response => {
                        commit('updateInvite', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('setInvitesLoadingState', false)
                    })
            })
        },
        invitesCreateRequest: ({commit}, invite) => {
            commit('setInvitesLoadingState', true)
            return new Promise((resolve, reject) => {
                axios.post('/admin/invites', invite)
                    .then(response => {
                        commit('addInvite', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('setInvitesLoadingState', false)
                    })
            })
        },
        invitesDeleteRequest: ({commit}, invite) => {
            commit('setInvitesLoadingState', true)
            return new Promise((resolve, reject) => {
                axios.delete(`/admin/invites/${invite.invite_id}`)
                    .then(response => {
                        commit('deleteInvite', invite)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('setInvitesLoadingState', false)
                    })
            })
        }
    }
}

export default invites
