import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementRuLocale from 'element-ui/lib/locale/lang/ru-RU'

Vue.use(VueI18n)

const LOCALE_KEY = 'locale'

const pageTitles = {
    en: {
        login: 'Sign in',
        register: 'Register',
        passwordReset: 'Password reset',
        admin: 'Administration',
        optimizedSettings: 'Optimized settings',
        dashboard: 'Dashboard',
        presentation: 'Presentation',
        users: 'Users',
        licenses: 'Licenses',
        accounts: 'Accounts',
        invites: 'Invites',
    },
    ru: {
        login: 'Вход',
        register: 'Регистрация',
        passwordReset: 'Сброс пароля',
        admin: 'Администрирование',
        optimizedSettings: 'Оптимизированные настройки',
        dashboard: 'Личный кабинет',
        presentation: 'Презентация',
        users: 'Пользователи',
        licenses: 'Лицензии',
        accounts: 'Счета',
        invites: 'Инвайты',
    }
}

const messages = {
    en: {
        ...elementEnLocale,
        pageTitles: pageTitles.en
    },
    ru: {
        ...elementRuLocale,
        pageTitles: pageTitles.ru
    },
}

const i18n = new VueI18n({
    // Set locale
    locale: localStorage.getItem(LOCALE_KEY) || 'en',
    // Set locale messages
    messages,
})

// Change locale and save to localStorage
export function changeLocale(locale) {
    if(!Object.keys(messages).includes(locale)) {
        return
    }

    i18n.locale = locale
    localStorage.setItem(LOCALE_KEY, locale)
}

export default i18n
