import axios from '@/plugins/axios'

const licenses = {
    state: {
        licenses: [],
        licensesLoadingState: false
    },
    getters: {
        licenses: state => state.licenses,
        licensesLoadingState: state => state.licensesLoadingState,
    },
    mutations: {
        setLicenses: (state, licenses) => state.licenses = licenses,
        setLicensesLoadingState: (state, isLoading) => state.licensesLoadingState = isLoading,
        updateLicense: (state, license) => {
            let licenseIndex = state.licenses.map(license => license.license_id).indexOf(license.license_id)
            state.licenses.splice(licenseIndex, 1, license)
        },
        addLicense: (state, license) => state.licenses.unshift(license),
    },
    actions: {
        licensesGetRequest: ({commit}) => {
            commit('setLicensesLoadingState', true)
            return new Promise((resolve, reject) => {
                axios.get('/admin/licenses')
                    .then(response => {
                        commit('setLicenses', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('setLicensesLoadingState', false)
                    })
            })
        },
        licenseUpdateRequest: ({commit}, payload) => {
            commit('setLicensesLoadingState', true)
            return new Promise((resolve, reject) => {
                axios.patch('/admin/licenses/' + payload.license_id, payload)
                    .then(response => {
                        commit('updateLicense', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('setLicensesLoadingState', false)
                    })
            })
        },
        licenseCreateRequest: ({commit}, payload) => {
            commit('setLicensesLoadingState', true)
            return new Promise((resolve, reject) => {
                axios.post('/admin/licenses', payload)
                    .then(response => {
                        commit('addLicense', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('setLicensesLoadingState', false)
                    })
            })
        }
    }
}

export default licenses
