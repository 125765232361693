const TOKEN_NAME = 'token'

export function getToken() {
    return localStorage.getItem(TOKEN_NAME)
}

export function saveToken(token) {
    localStorage.setItem(TOKEN_NAME, token)
}

export function deleteToken() {
    localStorage.removeItem(TOKEN_NAME)
}
