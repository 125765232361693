import axios from '@/plugins/axios'

const fxSymbols = {
    namespaced: true,
    state: {
        symbols: [],
    },
    getters: {
        symbols: state => state.symbols
    },
    mutations: {
        setSymbols: (state, symbols) => {
            state.symbols = symbols
        }
    },
    actions: {
        symbolsGetRequest: ({commit}) => {
            return new Promise((resolve, reject) => {
                axios.get('/admin/symbols')
                    .then(response => {
                        commit('setSymbols', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('setSymbols', [])
                        reject(error)
                    })
            })
        }
    }
}

export default fxSymbols
