import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/modules/app'
import session from '@/store/modules/session'
import profile from '@/store/modules/profile'
import admin from '@/store/modules/admin/admin'
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        // Store modules
        app,
        session,
        profile,
        admin,
    }
})

export default store
