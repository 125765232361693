import axios from "@/plugins/axios"

const profile = {
    state: {
        profile: null,
        loading: false,
    },
    getters: {
        PROFILE: state => state.profile,
        PROFILE_ROLE: state => state.profile ? state.profile.role : null,
        PROFILE_LOADING_STATE: state => state.loading
    },
    mutations: {
        SET_PROFILE: (state, profileData) => state.profile = profileData,
        SET_PROFILE_LOADING_STATE: (state, isLoading) => state.loading = isLoading
    },
    actions: {
        PROFILE_GET_REQUEST: ({commit}) => {
            commit('SET_PROFILE_LOADING_STATE', true)
            return new Promise((resolve, reject) => {
                axios.get('/profile')
                    .then(response => {
                        commit('SET_PROFILE', response.data)
                        resolve(response)
                    })
                    .catch(e => {
                        commit('SET_PROFILE', null)
                        reject(e)
                    })
                    .finally(() => {
                        commit('SET_PROFILE_LOADING_STATE', false)
                    })
            })
        },
        PROFILE_CREATE_REQUEST: ({commit}, payload) => {
            return new Promise((resolve, reject) => {
                commit('SET_PROFILE_LOADING_STATE', true)
                axios.post('/profile', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('SET_PROFILE_LOADING_STATE', false)
                    })
            })
        },
        PROFILE_SEND_RESET_PASSWORD_LINK: ({commit}, payload) => {
            return new Promise((resolve, reject) => {
                commit('SET_PROFILE_LOADING_STATE', true)
                axios.post('/password/send-reset-link', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('SET_PROFILE_LOADING_STATE', false)
                    })
            })
        },
        PROFILE_SET_NEW_PASSWORD: ({commit}, payload) => {
            return new Promise((resolve, reject) => {
                commit('SET_PROFILE_LOADING_STATE', true)
                axios.post('/password/reset', payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('SET_PROFILE_LOADING_STATE', false)
                    })
            })
        }
    }
}

export default profile
