import axios from "@/plugins/axios"
import {deleteToken, saveToken} from "@/utils/token"

const session = {
    state: {
        loading: false,
    },
    getters: {
        SESSION_LOADING_STATE: state => state.loading,
    },
    mutations: {
        SET_SESSION_LOADING: (state, isLoading) => state.loading = isLoading,
        SET_SESSION_TOKEN: (state, token) => state.token = token,
    },
    actions: {
        SESSION_CREATE_REQUEST: ({commit}, payload) => {
            commit('SET_SESSION_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.post('/sessions', payload)
                    .then(response => {
                        saveToken(response.data.token)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('SET_SESSION_LOADING', false)
                    })
            })
        },
        SESSION_DELETE_REQUEST: ({commit}) => {
            commit('SET_SESSION_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.delete('/sessions')
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        deleteToken()
                        commit('SET_SESSION_LOADING', false)
                    })
            })
        }
    }
}

export default session
