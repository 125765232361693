import axios from '@/plugins/axios'

const users = {
    state: {
        users: [],
        usersLoadingState: false,
    },
    getters: {
        users: state => state.users,
        usersLoadingState: state => state.usersLoadingState
    },
    mutations: {
        setUsers: (state, users) => state.users = users,
        updateUsers: (state, userData) => {
            let userIndex = state.users.map(user => user['user_id']).indexOf(userData['user_id'])
            state.users.splice(userIndex, 1, userData)
        },
        setUsersLoadingState: (state, isLoading) => state.usersLoadingState = isLoading
    },
    actions: {
        usersGetRequest: ({commit}) => {
            commit('setUsersLoadingState', true)
            return new Promise(((resolve, reject) => {
                axios.get('/admin/users')
                    .then(response => {
                        commit('setUsers', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        commit('setUsers', [])
                        reject(error)
                    })
                    .finally(() => {
                        commit('setUsersLoadingState', false)
                    })
            }))
        },
        usersUpdateRequest: ({commit}, payload) => {
            commit('setUsersLoadingState', true)
            return new Promise((resolve, reject) => {
                axios.patch('/admin/users/' + payload['user_id'], payload)
                    .then(response => {
                        commit('updateUsers', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('setUsersLoadingState', false)
                    })
            })
        }
    },
}

export default users
