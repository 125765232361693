export default function (Highcharts) {
    const H = Highcharts
    H.seriesType(
        'fractals',
        'sma', {
            lineWidth: 0,
            states: {
                hover: {
                    lineWidthPlus: 0
                }
            },
            params: {}
        }, {
            getValues: function (series) {
                // X values
                var xVal = series.xData;
                // Y values [open, high, low, close]
                var yVal = series.yData;

                var fractalsArray = [],
                    xData = [],
                    yData = [],
                    barsCount = xVal.length,
                    i = barsCount - 3;
                while(i >= 2) {
                    // Fractals up
                    var bFound = false;
                    var dCurrent = yVal[i][1]; // High
                    if(dCurrent > yVal[i + 1][1] && dCurrent > yVal[i + 2][1] && dCurrent>yVal[i - 1][1] && dCurrent>yVal[i - 2][1]) {
                        bFound = true;
                        yData.unshift(dCurrent);
                        xData.unshift(xVal[i]);
                        fractalsArray.unshift({
                            x: xVal[i],
                            y: dCurrent,
                            marker: {
                                enabled: true,
                                symbol: 'triangle',
                                fillColor: '#74b0f1'
                            },
                        });
                    }
                    // 6 bars Fractal
                    if(!bFound && (barsCount - i - 1) >= 3) {
                        if(dCurrent === yVal[i + 1][1] && dCurrent > yVal[i + 2][1] && dCurrent > yVal[i + 3][1] && dCurrent > yVal[i - 1][1] && dCurrent > yVal[i - 2][1]) {
                            bFound=true;
                            yData.unshift(dCurrent);
                            xData.unshift(xVal[i]);
                            fractalsArray.unshift({
                                x: xVal[i],
                                y: dCurrent,
                                marker: {
                                    enabled: true,
                                    symbol: 'triangle',
                                    fillColor: '#74b0f1'
                                },
                            });
                        }
                    }
                    // 7 bars Fractal
                    if(!bFound && (barsCount - i - 1) >= 4) {
                        if(dCurrent >= yVal[i + 1][1] && dCurrent === yVal[i + 2][1] && dCurrent > yVal[i + 3][1] && dCurrent > yVal[i + 4][1] && dCurrent > yVal[i - 1][1] && dCurrent > yVal[i - 2][1]) {
                            bFound=true;
                            yData.unshift(dCurrent);
                            xData.unshift(xVal[i]);
                            fractalsArray.unshift({
                                x: xVal[i],
                                y: dCurrent,
                                marker: {
                                    enabled: true,
                                    symbol: 'triangle',
                                    fillColor: '#74b0f1'
                                },
                            });
                        }
                    }
                    // 8 bars Fractal
                    if(!bFound && (barsCount - i - 1) >= 5) {
                        if(dCurrent >= yVal[i + 1][1] && dCurrent === yVal[i + 2][1] && dCurrent === yVal[i + 3][1] && dCurrent > yVal[i + 4][1] && dCurrent > yVal[i + 5][1] && dCurrent > yVal[i - 1][1] && dCurrent > yVal[i - 2][1]) {
                            bFound=true;
                            yData.unshift(dCurrent);
                            xData.unshift(xVal[i]);
                            fractalsArray.unshift({
                                x: xVal[i],
                                y: dCurrent,
                                marker: {
                                    enabled: true,
                                    symbol: 'triangle',
                                    fillColor: '#74b0f1'
                                },
                            });
                        }
                    }
                    // 9 bars Fractal
                    if(!bFound && (barsCount - i - 1) >= 6) {
                        if(dCurrent >= yVal[i + 1][1] && dCurrent === yVal[i + 2][1] && dCurrent >= yVal[i + 3][1] && dCurrent === yVal[i + 4][1] && dCurrent > yVal[i + 5][1] && dCurrent > yVal[i + 6][1] && dCurrent > yVal[i - 1][1] && dCurrent > yVal[i - 2][1]) {
                            bFound=true;
                            yData.unshift(dCurrent);
                            xData.unshift(xVal[i]);
                            fractalsArray.unshift({
                                x: xVal[i],
                                y: dCurrent,
                                marker: {
                                    enabled: true,
                                    symbol: 'triangle',
                                    fillColor: '#74b0f1'
                                },
                            });
                        }
                    }

                    // Fractals down
                    bFound = false;
                    dCurrent = yVal[i][2]; // Low
                    if(dCurrent < yVal[i + 1][2] && dCurrent < yVal[i + 2][2] && dCurrent < yVal[i - 1][2] && dCurrent < yVal[i - 2][2]) {
                        bFound = true;
                        yData.unshift(dCurrent);
                        xData.unshift(xVal[i]);
                        fractalsArray.unshift({
                            x: xVal[i],
                            y: dCurrent,
                            marker: {
                                enabled: true,
                                symbol: 'triangle-down',
                                fillColor: '#74b0f1'
                            },
                        });
                    }
                    // 6 bars Fractal
                    if(!bFound && (barsCount - i - 1) >= 3) {
                        if(dCurrent === yVal[i + 1][2] && dCurrent < yVal[i + 2][2] && dCurrent < yVal[i + 3][2] &&
                            dCurrent < yVal[i - 1][2] && dCurrent<yVal[i - 2][2]) {
                            bFound=true;
                            yData.unshift(dCurrent);
                            xData.unshift(xVal[i]);
                            fractalsArray.unshift({
                                x: xVal[i],
                                y: dCurrent,
                                marker: {
                                    enabled: true,
                                    symbol: 'triangle-down',
                                    fillColor: '#74b0f1'
                                },
                            });
                        }
                    }
                    // 7 bars Fractal
                    if(!bFound && (barsCount - i - 1) >= 4) {
                        if(dCurrent <= yVal[i + 1][2] && dCurrent === yVal[i + 2][2] && dCurrent < yVal[i + 3][2] && dCurrent < yVal[i + 4][2] &&
                            dCurrent < yVal[i - 1][2] && dCurrent < yVal[i - 2][2]) {
                            bFound=true;
                            yData.unshift(dCurrent);
                            xData.unshift(xVal[i]);
                            fractalsArray.unshift({
                                x: xVal[i],
                                y: dCurrent,
                                marker: {
                                    enabled: true,
                                    symbol: 'triangle-down',
                                    fillColor: '#74b0f1'
                                },
                            });
                        }
                    }
                    // 8 bars Fractal
                    if(!bFound && (barsCount - i - 1) >= 5) {
                        if(dCurrent<=yVal[i + 1][2] && dCurrent === yVal[i + 2][2] && dCurrent === yVal[i + 3][2] && dCurrent < yVal[i + 4][2] && dCurrent < yVal[i + 5][2] &&
                            dCurrent < yVal[i - 1][2] && dCurrent < yVal[i - 2][2])
                        {
                            bFound=true;
                            yData.unshift(dCurrent);
                            xData.unshift(xVal[i]);
                            fractalsArray.unshift({
                                x: xVal[i],
                                y: dCurrent,
                                marker: {
                                    enabled: true,
                                    symbol: 'triangle-down',
                                    fillColor: '#74b0f1'
                                },
                            });
                        }
                    }
                    // 9 bars Fractal
                    if(!bFound && (barsCount - i - 1) >= 6) {
                        if(dCurrent <= yVal[i + 1][2] && dCurrent === yVal[i + 2][2] && dCurrent <= yVal[i + 3][2] && dCurrent === yVal[i + 4][2] && dCurrent < yVal[i + 5][2] && dCurrent < yVal[i + 6][2] && dCurrent < yVal[i - 1][2] && dCurrent < yVal[i - 2][2]) {
                            bFound=true;
                            xData.unshift(xVal[i]);
                            yData.unshift(dCurrent);
                            fractalsArray.unshift({
                                x: xVal[i],
                                y: dCurrent,
                                marker: {
                                    enabled: true,
                                    symbol: 'triangle-down',
                                    fillColor: '#74b0f1'
                                },
                            });
                        }
                    }
                    i--;
                }

                return {
                    values: fractalsArray,
                    xData: xData,
                    yData: yData
                };
            }
        }
    );
}
