import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/token'
import store from '@/store'
import i18n from "@/plugins/i18n"

Vue.use(VueRouter)

NProgress.configure({ showSpinner: false })

const whiteListRoutes = [
    '/auth/login',
    '/auth/register',
    '/auth/password-reset',
    '/auth/password-reset-set',
    '/tos',
    '/presentation',
]

// App routes
const routes = [
    {
        path: '/auth',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Auth'),
        children: [
            {
                path: 'login',
                component: () => import(/* webpackChunkName: "auth-login" */ '@/views/auth/Login'),
                meta: {
                    title: () => i18n.t('pageTitles.login'),
                }
            },
            {
                path: 'register',
                component: () => import(/* webpackChunkName: "auth-register" */ '@/views/auth/Register'),
                meta: {
                    title: () => i18n.t('pageTitles.register', 'Register'),
                }
            },
            {
                path: 'password-reset',
                component: () => import(/* webpackChunkName: "auth-password-reset-request" */ '@/views/auth/PasswordReset'),
                meta: {
                    title: () => i18n.t('pageTitles.passwordReset'),
                }
            },
            {
                path: 'password-reset-set',
                component: () => import(/* webpackChunkName: "auth-password-reset-set" */ '@/views/auth/PasswordResetSet'),
                meta: {
                    title: () => i18n.t('pageTitles.passwordReset')
                }
            }
        ]
    },
    {
        name: 'presentation',
        path: '/presentation',
        component: () => import(/* webpackChunkName: "presentation" */ '@/views/Presentation'),
        meta: {
            title: () => i18n.t('pageTitles.presentation')
        }
    },
    {
        name: 'admin',
        path: '/admin',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Admin'),
        meta: {
            title: () => i18n.t('pageTitles.admin'),
            roles: [1]
        },
        children: [
            {
                name: 'administration',
                path: 'administration',
                component: () => import(/* webpackChunkName: "admin-administration" */ '@/views/admin/Administration'),
                meta: {
                    title: () => i18n.t('pageTitles.admin'),
                    roles: [1]
                },
                children: [
                    {
                        name: 'administration-accounts',
                        path: 'accounts',
                        component: () => import('@/views/admin/administration/accounts'),
                        meta: {
                            title: () => i18n.t('pageTitles.admin'),
                            roles: [1]
                        },
                    },
                    {
                        name: 'administration-charts',
                        path: 'charts',
                        component: () => import('@/views/admin/administration/charts'),
                        meta: {
                            title: () => i18n.t('pageTitles.admin'),
                            roles: [1]
                        },
                    },
                    {
                        name: 'administration-settings',
                        path: 'settings',
                        component: () => import('@/views/admin/administration/settings'),
                        meta: {
                            title: () => i18n.t('pageTitles.admin'),
                            roles: [1]
                        },
                    },
                    {
                        name: 'administration-trades-live',
                        path: 'trades-live',
                        component: () => import('@/views/admin/administration/TradesLive'),
                        meta: {
                            title: () => i18n.t('pageTitles.admin')
                        }
                    },
                    {
                        name: 'administration-trades-history',
                        path: 'trades-history',
                        component: () => import('@/views/admin/administration/TradesHistory'),
                        meta: {
                            title: () => i18n.t('pageTitles.admin')
                        }
                    },
                    {
                        name: 'administration-stats',
                        path: 'stats',
                        component: () => import('@/views/admin/administration/Stats'),
                        meta: {
                            title: () => i18n.t('pageTitles.admin')
                        }
                    },
                    {
                        name: 'administration-reports',
                        path: 'reports',
                        component: () => import('@/views/admin/administration/Reports'),
                        meta: {
                            title: () => i18n.t('pageTitles.admin')
                        }
                    },
                    {
                        name: 'administration-log',
                        path: 'log',
                        component: () => import('@/views/admin/administration/log'),
                        meta: {
                            title: () => i18n.t('pageTitles.admin')
                        }
                    },
                    {
                        name: 'administration-review',
                        path: 'review',
                        component: () => import('@/views/admin/administration/Review'),
                        meta: {
                            title: () => i18n.t('pageTitles.admin')
                        }
                    },
                ]
            },
            {
                name: 'optimized-settings',
                path: 'optimized-settings',
                component: () => import(/* webpackChunkName: "admin-optimized-settings" */ '@/views/admin/OptimizedSettings'),
                meta: {
                    title: () => i18n.t('pageTitles.optimizedSettings'),
                    roles: [1]
                }
            },
            {
                path: 'users',
                component: () => import(/* webpackChunkName: "admin-users" */ '@/views/admin/Users'),
                meta: {
                    title: () => i18n.t('pageTitles.users'),
                    roles: [1]
                },
            },
            {
                path: 'licenses',
                component: () => import(/* webpackChunkName: "admin-licenses" */ '@/views/admin/Licenses'),
                meta: {
                    title: () => i18n.t('pageTitles.licenses'),
                    roles: [1]
                },
            },
            {
                path: 'accounts',
                component: () => import(/* webpackChunkName: "admin-accounts" */ '@/views/admin/Accounts'),
                meta: {
                    title: () => i18n.t('pageTitles.accounts'),
                    roles: [1]
                },
            },
            {
                path: 'invites',
                component: () => import(/* webpackChunkName: "admin-invites" */ '@/views/admin/Invites'),
                meta: {
                    title: () => i18n.t('pageTitles.invites'),
                    roles: [1]
                },
            }
        ]
    },
    {
        path: '/dashboard',
        component: () => import(/* webpackChunkName: "user-dashboard" */ '@/views/user/Dashboard'),
        meta: {
            title: () => i18n.t('pageTitles.dashboard'),
        }
    },
    {
        path: '/404',
        component: () => import(/* webpackChunkName: "not-found-404" */ '@/views/PageNotFound404'),
    },
    {
        path: '/',
    },
    {
        path: '*',
        redirect: '/404',
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach(async (to, from, next) => {
    NProgress.start()

    // Check if user logged in
    const hasToken = getToken()

    // If user doesnt have token
    if (!hasToken) {
        // If rote in white list than allow to go to required route
        if (whiteListRoutes.includes(to.path)) {
            next()
        } else {
            next('/auth/login')

        }
        NProgress.done()
        return
    }

    // If user has token
    if(hasToken) {
        // Check user role and token
        let userRole = store.getters.PROFILE_ROLE
        if(userRole === null) {
            await store.dispatch('PROFILE_GET_REQUEST')
            userRole = store.getters.PROFILE_ROLE
        }

        // There is no reason to go to auth routes if the user has a valid token
        if(to.path === '/login' || to.path === '/register' || to.path === '/password-reset' || to.path === '/') {
            if(userRole === 0) {
                next('/dashboard')
            }

            if(userRole === 1) {
                next('/admin')
            }
            NProgress.done()
            return
        }

        if(Object.prototype.hasOwnProperty.call(to.meta, 'roles')) {
            if(to.meta.roles.includes(userRole)) {
                next()
            } else {
                next('/')
            }
        } else {
            next()
        }
        NProgress.done()
    }
})

router.afterEach((to) => {
    // Set page title
    document.title = `inFibo - ${ to.meta.title() }`

    NProgress.done()
})

export default router
