export default function (Highcharts) {
    const H = Highcharts
    H.seriesTypes.ikh.prototype.drawGraph = function() {
        var SMA = H.seriesTypes.sma;
        var indicator = this,
            mainLinePoints = indicator.points,
            pointsLength = mainLinePoints.length,
            mainLineOptions = indicator.options,
            mainLinePath = indicator.graph,
            mainColor = indicator.color,
            gappedExtend = {
                options: {
                    gapSize: mainLineOptions.gapSize
                }
            },
            pointArrayMapLength = indicator.pointArrayMap.length,
            allIchimokuPoints = [
                [],
                [],
                [],
                [],
                [],
                []
            ],
            position,
            point,
            i,
            intersectIndexColl = [],
            upColor = mainLineOptions.senkouSpan.styles.overColor || 'rgba(0, 255, 0, 0.3)',
            downColor = mainLineOptions.senkouSpan.styles.underColor || 'rgba(255, 0, 0, 0.3)';


        // Generate points for all lines and spans lines:
        while (pointsLength--) {
            point = mainLinePoints[pointsLength];
            for (i = 0; i < pointArrayMapLength; i++) {
                position = indicator.pointArrayMap[i];

                if (H.defined(point[position])) {
                    allIchimokuPoints[i].push({
                        plotX: point.plotX,
                        plotY: point['plot' + position],
                        isNull: false
                    });
                }
            }

            if (pointsLength !== mainLinePoints.length - 1) {
                // check if lines intersect
                var index = allIchimokuPoints[4].length - 1,
                    intersect = getLineIntersection(
                        allIchimokuPoints[3][index - 1],
                        allIchimokuPoints[3][index],
                        allIchimokuPoints[4][index - 1],
                        allIchimokuPoints[4][index]
                    );

                if (intersect) {
                    H.each([3, 4], function(n) {
                        // if lines intersect add intersect point to ichimoku points collection
                        allIchimokuPoints[n].splice(index, 0, {
                            plotX: intersect.plotX,
                            plotY: intersect.plotY,
                            isNull: false,
                            intersectPoint: true
                        })
                    });

                    intersectIndexColl.push(index);
                }
            }

        }


        // Modify options and generate lines:
        H.each([
            'tenkanLine',
            'kijunLine',
            'chikouLine',
            'senkouSpanA',
            'senkouSpanB',
            'senkouSpan'
        ], function(lineName, i) {

            if (mainLineOptions[lineName] !== undefined) {
                // First line is rendered by default option
                indicator.points = allIchimokuPoints[i];
                indicator.options = H.merge(
                    mainLineOptions[lineName].styles,
                    gappedExtend
                );
                indicator.graph = indicator['graph' + lineName];
            }

            // For span, we need an access to the next points, used in
            // getGraphPath()
            indicator.nextPoints = allIchimokuPoints[i - 1];

            // if graphColection exist then remove svg element and indicator property
            if (indicator.graphCollection) {
                indicator.graphCollection.forEach(function(graphName) {
                    indicator[graphName].element.remove();
                    delete indicator[graphName];
                });
            }

            // clean grapCollection or initialize it
            indicator.graphCollection = [];

            if (i === 5 && allIchimokuPoints[3][0] && allIchimokuPoints[4][0]) {

                // add first and last point to senkouSpan area sections
                intersectIndexColl.unshift(0);
                intersectIndexColl.push(allIchimokuPoints[3].length - 1);

                // draw each senkouSpan area sections
                for (var j = 0; j < intersectIndexColl.length - 1; j++) {

                    var startIntersect = intersectIndexColl[j],
                        endIntersect = intersectIndexColl[j + 1];

                    indicator.points = allIchimokuPoints[i - 1].slice(startIntersect, endIntersect + 1);
                    indicator.options = H.merge(
                        mainLineOptions[lineName].styles,
                        gappedExtend
                    );

                    indicator.graph = indicator['graph' + lineName + j];
                    indicator.nextPoints = allIchimokuPoints[i - 2].slice(startIntersect, endIntersect + 1);

                    indicator.fillGraph = true;

                    // which color up or down to use
                    // check the middle point if we can
                    if (Math.floor(indicator.points.length / 2) >= 1) {
                        var x = Math.floor(indicator.points.length / 2);

                        // middle point has equal values - then check ponints sum to decide which color
                        if (indicator.points[x].plotY === indicator.nextPoints[x].plotY) {

                            var pointsPlotYSum = 0,
                                nextPointsPlotYSum = 0;
                            for (var k = 0; k < indicator.points.length; k++) {
                                pointsPlotYSum += indicator.points[k].plotY;
                                nextPointsPlotYSum += indicator.nextPoints[k].plotY;
                            }

                            indicator.color = (pointsPlotYSum > nextPointsPlotYSum) ? upColor : downColor;

                        } else {
                            indicator.color = (indicator.points[x].plotY > indicator.nextPoints[x].plotY) ? upColor : downColor;
                        }
                    } else {
                        indicator.color = (indicator.points[0].plotY > indicator.nextPoints[0].plotY) ? upColor : downColor;
                    }



                    SMA.prototype.drawGraph.call(indicator);
                    indicator['graph' + lineName + j] = indicator.graph;
                    indicator.graphCollection.push('graph' + lineName + j);
                }

            } else {
                indicator.fillGraph = false;
                indicator.color = mainColor;
                SMA.prototype.drawGraph.call(indicator);
                indicator['graph' + lineName] = indicator.graph;
            }

        });
        // Clean temporary properties:
        delete indicator.nextPoints;
        delete indicator.fillGraph;

        // Restore options and draw the Tenkan line:
        indicator.points = mainLinePoints;
        indicator.options = mainLineOptions;
        indicator.graph = mainLinePath;
    }
}

const getLineIntersection = function (p0, p1, p2, p3) {
    if (p0 !== undefined && p1 !== undefined && p2 !== undefined && p3 !== undefined) {
        let s1_x = p1.plotX - p0.plotX,
            s1_y = p1.plotY - p0.plotY,
            s2_x = p3.plotX - p2.plotX,
            s2_y = p3.plotY - p2.plotY,
            s,
            t

        s = (-s1_y * (p0.plotX - p2.plotX) + s1_x * (p0.plotY - p2.plotY)) / (-s2_x * s1_y + s1_x * s2_y)
        t = (s2_x * (p0.plotY - p2.plotY) - s2_y * (p0.plotX - p2.plotX)) / (-s2_x * s1_y + s1_x * s2_y)

        if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
            return {
                plotX: p0.plotX + (t * s1_x),
                plotY: p0.plotY + (t * s1_y)
            }
        }
    }

    return false
}
