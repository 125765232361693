import Vue from 'vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import stockInit from "highcharts/modules/stock"
import boostInit from "highcharts/modules/boost"
import indicatorsInit from 'highcharts/indicators/indicators'
import ichimokuInit from 'highcharts/indicators/ichimoku-kinko-hyo'
import ichimokuCloudColor from './ichimoku-kinko-hyo-cloud-colors'
import bollingerBandsInit from 'highcharts/indicators/bollinger-bands'
import psarInit from 'highcharts/indicators/psar.src'
import fractalsInit from './highcharts-indicators-fractals'
import scaleYInit from './highcharts-y-scale'
import priceLineInit from './highcharts-price-line'

stockInit(Highcharts)
boostInit(Highcharts)
indicatorsInit(Highcharts)
ichimokuInit(Highcharts)
ichimokuCloudColor(Highcharts)
bollingerBandsInit(Highcharts)
psarInit(Highcharts)
fractalsInit(Highcharts)
scaleYInit(Highcharts)
priceLineInit(Highcharts)

Vue.use(HighchartsVue, {
    highcharts: Highcharts
})

// Highcharts.setOptions({
//     lang: {
//         loading: 'Загрузка...',
//         months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
//         weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
//         shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
//         exportButtonTitle: "Экспорт",
//         printButtonTitle: "Печать",
//         rangeSelectorFrom: "С",
//         rangeSelectorTo: "По",
//         rangeSelectorZoom: "Период",
//         downloadPNG: 'Скачать PNG',
//         downloadJPEG: 'Скачать JPEG',
//         downloadPDF: 'Скачать PDF',
//         downloadSVG: 'Скачать SVG',
//         printChart: 'Напечатать график',
//         resetZoom: 'Сбросить увеличение',
//     }
// })
