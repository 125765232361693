import axios from '@/plugins/axios'

const symbolSettings = {
    state: {
        symbolSettings: [],
        symbolSettingsLoadingState: false,
        updateSymbolSettingsLoadingState: false,
    },
    getters: {
        symbolSettings: state => state.symbolSettings,
        symbolSettingsLoadingState: state => state.symbolSettingsLoadingState,
        updateSymbolSettingsLoadingState: state => state.updateSymbolSettingsLoadingState,
    },
    mutations: {
        setSymbolSettings: (state, symbolSettings) => state.symbolSettings = symbolSettings,
        setSymbolSettingsLoadingState: (state, isLoading) => state.symbolSettingsLoadingState = isLoading,
        setUpdateSymbolSettingsLoadingState: (state, isLoading) => state.updateSymbolSettingsLoadingState = isLoading,
        updateSymbolSetting: (state, symbolSetting) => {
            const index = state.symbolSettings.map(setting => setting.symbol_settings_id).indexOf(symbolSetting.symbol_settings_id)
            if(index !== -1) {
                state.symbolSettings.splice(index, 1, symbolSetting)
            }
        }
    },
    actions: {
        getSymbolSettings: ({commit}, params) => {
            return new Promise((resolve, reject) => {
                axios.get('/admin/symbol-settings', {
                    params
                })
                    .then(response => {
                        commit('setSymbolSettings', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('setSymbolSettings', [])
                        reject(error)
                    })
            })
        },
        updateSymbolSetting: ({commit}, symbolSetting) => {
            return new Promise((resolve, reject) => {
                commit('setUpdateSymbolSettingsLoadingState', true)
                axios.patch('/admin/symbol-settings/' + symbolSetting.symbol_settings_id, symbolSetting)
                    .then(response => {
                        commit('updateSymbolSetting', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        commit('setUpdateSymbolSettingsLoadingState', false)
                    })
            })
        }
    }
}

export default symbolSettings
