const LOG_KEY = 'admin-log'

const commandsOptions = [
    {
        value: 'open_pending',
        label: 'Open Pending'
    },
    {
        value: 'close_pending',
        label: 'Close Pending'
    },
    {
        value: 'open_market',
        label: 'Open Market'
    },
    {
        value: 'close_market',
        label: 'Close Market'
    }
]

const logSettings = {
    namespaced: true,
    state: {
        notificationsState: true,
        notificationsSelectedCommands: commandsOptions.map(command => command.value),
    },
    getters: {
        notificationsState: state => {
            return state.notificationsState
        },
        notificationsSelectedCommands: state => {
            return state.notificationsSelectedCommands
        },
        commandsOptions: () => commandsOptions,
    },
    mutations: {
        setNotificationsState: (state, notificationsState) => {
            state.notificationsState = notificationsState
        },
        setNotificationsSelectedCommands: (state, notificationsSelectedCommands) => {
            state.notificationsSelectedCommands = notificationsSelectedCommands
        }
    },
    actions: {
        loadState: ({state}) => {
            try {
                const lsState = JSON.parse(window.localStorage.getItem(LOG_KEY))
                if(!lsState) {
                    return
                }
                if(Object.prototype.hasOwnProperty.call(lsState, 'notificationsState')) {
                    state.notificationsState = lsState.notificationsState
                }
                if(Object.prototype.hasOwnProperty.call(lsState, 'notificationsSelectedCommands')) {
                    state.notificationsSelectedCommands = lsState.notificationsSelectedCommands
                }
            } catch (e) {
                window.localStorage.removeItem(LOG_KEY)
                console.warn(e)
            }
        },
        saveState: ({state}) => {
            window.localStorage.setItem(LOG_KEY, JSON.stringify(state))
        }
    },
}

export default logSettings
