const CHARTS_SETTINGS_KEY = 'adminnistration-charts-settings'

const chartsSettings = {
    namespaced: true,
    state: {
        isTrendsAndSignalsVisible: true,
        isAccumulatedProfitVisible: true,
        candlesLimit: 100,
        selectedPeriod: 15,
        selectedIndicators: [],
    },
    getters: {
        isTrendsAndSignalsVisible: state => state.isTrendsAndSignalsVisible,
        isAccumulatedProfitVisible: state => state.isAccumulatedProfitVisible,
        candlesLimit: state => state.candlesLimit,
        selectedPeriod: state => state.selectedPeriod,
        selectedIndicators: state => state.selectedIndicators,
    },
    mutations: {
        setIsTrendsAndSignalsVisible: (state, isTrendsAndSignalsVisible) => state.isTrendsAndSignalsVisible = isTrendsAndSignalsVisible,
        setIsAccumulatedProfitVisible: (state, isAccumulatedProfitVisible) => state.isAccumulatedProfitVisible = isAccumulatedProfitVisible,
        setCandlesLimit: (state, candlesLimit) => state.candlesLimit = candlesLimit,
        setSelectedPeriod: (state, selectedPeriod) => state.selectedPeriod = selectedPeriod,
        setSelectedIndicators: (state, selectedIndicators) => state.selectedIndicators = selectedIndicators,
    },
    actions: {
        loadState: ({state}) => {
            try {
                const lsState = JSON.parse(window.localStorage.getItem(CHARTS_SETTINGS_KEY))
                if(!lsState) {
                    return
                }
                if(Object.prototype.hasOwnProperty.call(lsState, 'isTrendsAndSignalsVisible')) {
                    state.isTrendsAndSignalsVisible = lsState.isTrendsAndSignalsVisible
                }
                if(Object.prototype.hasOwnProperty.call(lsState, 'isAccumulatedProfitVisible')) {
                    state.isAccumulatedProfitVisible = lsState.isAccumulatedProfitVisible
                }
                if(Object.prototype.hasOwnProperty.call(lsState, 'candlesLimit')) {
                    state.candlesLimit = lsState.candlesLimit
                }
                if(Object.prototype.hasOwnProperty.call(lsState, 'selectedPeriod')) {
                    state.selectedPeriod = lsState.selectedPeriod
                }
                if(Object.prototype.hasOwnProperty.call(lsState, 'selectedIndicators')) {
                    state.selectedIndicators = lsState.selectedIndicators
                }
            } catch (e) {
                window.localStorage.removeItem(CHARTS_SETTINGS_KEY)
                console.warn(e)
            }
        },
        saveState: ({state}) => {
            window.localStorage.setItem(CHARTS_SETTINGS_KEY, JSON.stringify(state))
        }
    }
}

export default chartsSettings
