const SELECTED_COLUMNS_KEY = 'admin-accounts-table-selected-columns'
const SELECTED_ACCOUNTS_KEY = 'admin-accounts-table-selected-accounts'
const PROFIT_LOSS_OFFSET_KEY = 'admin-accounts-table-profit-loss-offset'
const MARGIN_LIMIT_KEY = 'admin-accounts-table-margin-limit'
const MAX_PROFIT_BALANCE_RATION_KEY = 'admin-accounts-table-max-profit-balance-ratio'
const MAX_LOTS_KEY = 'admin-accounts-table-max-lots'

const accountsSettings = {
    namespaced: true,
    state: {
        selectedAccountsLoaded: false,
        selectedAccounts: [],

        selectedColumnsLoaded: false,
        columnsOptions: [],
        columnsNames: [],
        selectedColumns: [],

        profitLossOffset: Number(window.localStorage.getItem(PROFIT_LOSS_OFFSET_KEY)),
        marginLimit: window.localStorage.getItem(MARGIN_LIMIT_KEY) ?? 300,
        maxProfitBalanceRatio: window.localStorage.getItem(MAX_PROFIT_BALANCE_RATION_KEY) ?? 0.4,
        maxLots: window.localStorage.getItem(MAX_LOTS_KEY) ?? 2.0
    },
    getters: {
        selectedAccounts: (state) => {
            if(!state.selectedAccountsLoaded) {
                try {
                    const selectedAccounts = JSON.parse(window.localStorage.getItem(SELECTED_ACCOUNTS_KEY))
                    if (selectedAccounts && selectedAccounts.length > 0) {
                        state.selectedAccounts = [...selectedAccounts]
                    }
                } catch (e) {
                    console.warn(e)
                }
                state.selectedAccountsLoaded = true
            }

            return state.selectedAccounts
        },
        columnsOptions: state => state.columnsOptions,
        selectedColumns: state => {
            if(!state.selectedColumnsLoaded) {
                state.selectedColumns = []
                try {
                    const selectedColumns = JSON.parse(window.localStorage.getItem(SELECTED_COLUMNS_KEY))
                    if(selectedColumns && selectedColumns.length) {
                        state.selectedColumns = selectedColumns
                    }
                } catch (e) {
                    console.warn('Can\'t parse admin accounts table selected columns')
                }
                state.selectedColumnsLoaded = true
            }

            // Check if there is columns that shouldn't be in selected columns
            if(state.columnsNames.length) {
                state.selectedColumns = state.selectedColumns.filter(columnName => state.columnsNames.includes(columnName))
            }

            // If there is no selected columns than select all
            if (state.selectedColumns.length === 0) {
                state.selectedColumns = state.columnsNames
            }

            return state.selectedColumns
        },
        profitLossOffset: state => state.profitLossOffset,
        marginLimit: state => Number(state.marginLimit),
        maxProfitBalanceRatio: state => Number(state.maxProfitBalanceRatio),
        maxLots: state => Number(state.maxLots)
    },
    mutations: {
        setColumnsOptions: (state, columnsOptions) => {
            state.columnsOptions = columnsOptions
            state.columnsNames = columnsOptions.map(column => column.value)
        },
        setSelectedColumns: (state, selectedColumns) => {
            state.selectedColumns = selectedColumns
            window.localStorage.setItem(SELECTED_COLUMNS_KEY, JSON.stringify(selectedColumns))
        },
        setSelectedAccounts: (state, selectedAccounts) => {
            state.selectedAccounts = selectedAccounts
            window.localStorage.setItem(SELECTED_ACCOUNTS_KEY, JSON.stringify(selectedAccounts))
        },
        setProfitLossOffset: (state, profitLossOffset) => {
            state.profitLossOffset = profitLossOffset
            window.localStorage.setItem(PROFIT_LOSS_OFFSET_KEY, profitLossOffset)
        },
        setMarginLimit: (state, marginLimit) => {
            state.marginLimit = marginLimit
            window.localStorage.setItem(MARGIN_LIMIT_KEY, marginLimit)
        },
        setMaxProfitBalanceRatio: (state, maxProfitBalanceRatio) => {
            state.maxProfitBalanceRatio = maxProfitBalanceRatio
            window.localStorage.setItem(MAX_PROFIT_BALANCE_RATION_KEY, maxProfitBalanceRatio)
        },
        setMaxLots: (state, maxLots) => {
            state.maxLots = maxLots
            window.localStorage.setItem(MAX_LOTS_KEY, maxLots)
        },
    }
}

export default accountsSettings
