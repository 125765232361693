import axios from '@/plugins/axios'

const accounts = {
    state: {
        accounts: [],
        accountsLoadingState: false,
    },
    getters: {
        accounts: state => state.accounts,
        accountsLoadingState: state => state.accountsLoadingState
    },
    mutations: {
        setAccounts: (state, accounts) => state.accounts = accounts,
        setAccountsLoadingState: (state, isLoading) => state.accountsLoadingState = isLoading,
    },
    actions: {
        accountsGetRequest: ({commit}) => {
            commit('setAccountsLoadingState', true)
            return new Promise((resolve, reject) => {
                axios.get('/admin/accounts')
                    .then(response => {
                        commit('setAccounts', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('setAccounts', [])
                        reject(error)
                    })
                    .finally(() => {
                        commit('setAccountsLoadingState', false)
                    })
            })
        }
    }
}

export default accounts
