import originalAxios from 'axios'
import {deleteToken, getToken} from '@/utils/token'
import router from '@/router'

const baseURL = process.env.VUE_APP_BASE_URL

const axios = originalAxios.create({
    baseURL,
    timeout: 20000,
    headers: {
        'Accept': 'application/json',
    }
})

// Add token to request
axios.interceptors.request.use(config => {
    config.headers['Authorization'] = 'Bearer ' + getToken()
    return config
})

// Check response status code. If 401 => redirect to login
axios.interceptors.response.use(
    response => response,
    async error => {
        if(error.response && error.response.status === 401) {
            deleteToken()
            await router.replace('/login')
        }
        return Promise.reject(error)
    }
)

export default axios
