import axios from '@/plugins/axios'

const optimizedSettings = {
    namespaced: true,
    state: {
        settings: [],
        optimizedSettingsLoadingState: false,
    },
    getters: {
        settings: state => state.settings,
        loadingState: state => state.optimizedSettingsLoadingState,
    },
    mutations: {
        setLoadingState: (state, isLoading) => {
            state.isLoading = isLoading
        },
        setSettings: (state, settings) => {
            state.settings = settings
        },
        addSettings: (state, setting) => {
            state.settings.push(setting)
        },
        updateSettings: (state, settings) => {
            const settingsIndex = state.settings.map(settings => settings.id).indexOf(settings.id)
            state.settings.splice(settingsIndex, 1, settings)
        },
        deleteSettings: (state, settingsId) => {
            const settingsIndex = state.settings.map(settings => settings.id).indexOf(settingsId)
            state.settings.splice(settingsIndex, 1)
        },
    },
    actions: {
        getRequest: ({commit}) => {
            return new Promise((resolve, reject) => {
                commit('setLoadingState', true)
                axios.get('/admin/optimized-settings')
                    .then(response => {
                        commit('setSettings', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('setSettings', [])
                        reject(error)
                    })
                    .finally(() => commit('setLoadingState', false))
            })
        },
        createRequest: ({commit}, settings) => {
            return new Promise((resolve, reject) => {
                commit('setLoadingState', true)
                axios.post('/admin/optimized-settings', settings)
                    .then(response => {
                        commit('addSettings', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => commit('setLoadingState', false))
            })
        },
        updateRequest: ({commit}, settings) => {
            return new Promise((resolve, reject) => {
                commit('setLoadingState', true)
                axios.patch(`/admin/optimized-settings/${settings.id}`, settings)
                    .then(response => {
                        commit('updateSettings', response.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => commit('setLoadingState', false))
            })
        },
        deleteRequest: ({commit}, id) => {
            return new Promise((resolve, reject) => {
                commit('setLoadingState', true)
                axios.delete(`/admin/optimized-settings/${id}`)
                    .then(response => {
                        commit('deleteSettings', id)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => commit('setLoadingState', false))
            })
        }
    }
}

export default optimizedSettings
