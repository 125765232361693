import axios from "@/plugins/axios"

const trades = {
    state: {
        accountSymbols: [],
    },
    getters: {
        accountSymbols: state => state.accountSymbols
    },
    mutations: {
        setAccountSymbols: (state, symbols) => state.accountSymbols = symbols,
    },
    actions: {
        accountSymbolsGetRequest: ({commit}, account_ids) => {
            return new Promise((resolve, reject) => {
                axios.get(`/admin/trades-symbols`, {
                    params: {
                        account_ids: account_ids.join(',')
                    }
                })
                    .then(response => {
                        // Sort symbols
                        const order = ['EURUSD', 'USDCHF', 'GBPUSD', 'USDJPY', 'NZDUSD']
                        let symbols = response.data.sort(function(a, b){
                            return order.indexOf(a) - order.indexOf(b)
                        })

                        // Remove blacklisted values
                        const blackList = ['PROFIT', 'profit']
                        symbols = symbols.filter(symbol => !blackList.includes(symbol))

                        commit('setAccountSymbols', symbols)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('setAccountSymbols', [])
                        reject(error)
                    })
            })
        },
        quotesGetRequest: (_, params) => {
            return new Promise((resolve, reject) => {
                axios.get('/quotes', {
                    params: params
                })
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        tradesGetRequest: (_, params) => {
            return new Promise((resolve, reject) => {
                axios.get('admin/trades', {
                    params: params
                })
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        tradesAccumulatedProfitGetRequest: (_, params) => {
            return new Promise((resolve, reject) => {
                axios.get('admin/trades/reports/accumulated-profit', {
                    params: params
                })
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
    }
}

export default trades
