import licenses from '@/store/modules/admin/licenses'
import users from '@/store/modules/admin/users'
import invites from '@/store/modules/admin/invites'
import accounts from '@/store/modules/admin/accounts'
import trades from '@/store/modules/admin/trades'
import symbolSettings from '@/store/modules/admin/symbolSettings'
import accountsSettings from '@/store/modules/admin/accountsSettings'
import chartsSettings from '@/store/modules/admin/chartsSettings'
import symbolSettingsSettings from '@/store/modules/admin/symbolSettingsSettings'
import logSettings from '@/store/modules/admin/logSettings'
import fxSymbols from '@/store/modules/admin/fxSymbols'
import optimizedSettings from '@/store/modules/admin/optimizedSettings'

const admin = {
    namespaced: true,
    modules: {
        licenses,
        users,
        invites,
        accounts,
        trades,
        symbolSettings,
        accountsSettings,
        chartsSettings,
        symbolSettingsSettings,
        logSettings,
        fxSymbols,
        optimizedSettings,
    }
}

export default admin
