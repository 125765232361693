const DEFAULT_SETTINGS_TYPE_KEY = 'admin-symbol-settings-default-type'

const symbolSettingsSettings = {
    namespaced: true,
    state: {
        defaultSettingsType: window.localStorage.getItem(DEFAULT_SETTINGS_TYPE_KEY) || 'general',
        defaultSettingsTypeOptions: [],
    },
    getters: {
        defaultSettingsType: state => state.defaultSettingsType,
        defaultSettingsTypeOptions: state => state.defaultSettingsTypeOptions,
    },
    mutations: {
        setDefaultSettingsType: (state, type) => {
            state.defaultSettingsType = type
            window.localStorage.setItem(DEFAULT_SETTINGS_TYPE_KEY, type)
        },
        setDefaultSettingsTypeOptions: (state, options) => {
            state.defaultSettingsTypeOptions = options
        },
    },
}

export default symbolSettingsSettings
