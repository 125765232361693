const app = {
    state: {
        adminSidebar: {
            collapsed: true,
            withoutAnimation: true,
        },
        device: 'desktop',
        adminSelectedAccount: null,
    },
    getters: {
        IS_ADMIN_SIDEBAR_COLLAPSED: state => state.adminSidebar.collapsed,
        IS_ADMIN_SIDEBAR_WITHOUT_ANIMATION: state => state.adminSidebar.withoutAnimation,
        DEVICE: state => state.device,
        ADMIN_SELECTED_ACCOUNT: state => state.adminSelectedAccount
    },
    mutations: {
        SET_ADMIN_SELECTED_ACCOUNT: (state, account) => {
            state.adminSelectedAccount = account
        },
    },
    actions: {
        // TODO: replace with mutations
        TOGGLE_ADMIN_SIDEBAR: ({state}) => {
            state.adminSidebar.collapsed = !state.adminSidebar.collapsed
            state.adminSidebar.withoutAnimation = false
        },
        CLOSE_ADMIN_SIDEBAR: ({state}, {withoutAnimation}) => {
            state.adminSidebar.collapsed = true
            state.adminSidebar.withoutAnimation = withoutAnimation
        },
        TOGGLE_DEVICE: ({state}, device) => {
            state.device = device
        },
    },
}

export default app
